import React from 'react'
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  switch: {
    color: theme.palette.checkbox.primary,
    fontSize: theme.text.fontSize.tertiary,
  },
}));

export default function ToogleDarkThemeSwitch(props) {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.lighton}
          onChange={() => props.setLight(!props.lighton)}
        />
      }
      label={props.lighton ? "Dark Theme" : "Light Theme"}
      className={classes.switch}
    />
  );
}
