import React from 'react'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondary,
    width: '100%',
    heihgt: "10vh",
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
  qandaRoute: {
    fontWeight: "bold",
    fontSize: theme.text.fontSize.secondary,
    color: theme.palette.text.quaternary,
    textAlign: "right",
    padding: "0 20px 10px 20px",
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid item xs={12} xl={12}>
        <footer>
          <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 10 }}>
            <Container
              maxWidth="lg"
              className={classes.container}
              ackgroundcolor="secondary"
            >
              <Grid container spacing={5}>
                <Grid item xs={12} sm={4}>
                  <RouterLink 
                      component={Link}
                      color="inherit"
                      to="/about"
                  >
                      Impressum
                  </RouterLink>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box>Quantlet &reg; {new Date().getFullYear()}</Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Link 
                    color="inherit" 
                    onClick={() => {
                      window.open("https://www.hu-berlin.de/de/hu/impressum/datenschutzerklaerung", 
                      "_blank")}
                    }>
                    Data Privacy
                  </Link>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </footer>
      </Grid>
    </Box>
  );
}
