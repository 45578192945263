import * as d3 from "d3";

import React, { useLayoutEffect, useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { random, pi, cos, sin } from "mathjs";

import _ from 'lodash';
import { Legend } from './legend';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('md')] :
  {
    svg: {
      height: "100%",
      width: "100%",
    }
  },
}))

function splitCircle(clusters, nodes){
  var sum = 0
  var pre = 0
  var x = 0
  var y = 0
  for (let [, cluster] of clusters.entries()) {
    pre = sum;
    sum += cluster.length
    x = 500 + 200*sin((pi)*((pre+sum)/nodes.length))
    y = 500 + 200*cos((pi)*((pre+sum)/nodes.length))
    for (let [, k] of cluster.entries()) {
      k.center = {
        x : x,
        y : y
      }
    k.theta = 2*pi * (sum/nodes.length)
    }
  }
}

function computeCenters(nodes){
  const sorted_data = _.sortBy(_.groupBy(nodes, 'artist'), function(user) { return user.length; }, ['asc']);

  splitCircle(sorted_data, nodes)

  return sorted_data.reduce((pre,  e) => {
    e.map((k) => {
        k.x = k.center.x + random()*20 // norm * sin(angle) + 500
        k.y = k.center.y + random()*20 // norm * cos(angle) + 500
        k.index = k.id
        k.r = 5
      return k 
    })
    return [...pre, ...e]
  })
}

function ForceGraph({nodes}) {

  const [animatedNodes, setAnimatedNodes] = useState(nodes);

  // const [svgHeight, setSVGHeight] = useState(1000)
  // const [svgWidth, setSVGWidth] = useState(1000)

  const dispatch = useDispatch();
  const clickReducer = useSelector((state) => state.clickReducer)
  const dataReducer = useSelector((state) => state.dataReducer)

  const [colors, setColors] = useState({})

  const classes = useStyles();

  const myRef = useRef(null)
  var width = 1000
  var height = 1000

  useEffect(()=>{
    setColors(dataReducer.colors)
  },[dataReducer])

  useEffect(()=>{

    setAnimatedNodes([])
    if (nodes.length) {
      setAnimatedNodes(computeCenters(nodes))
    } else {
      setAnimatedNodes([])
    }
  }, [nodes])


  useEffect(() => {
    if (myRef.current && animatedNodes.length){
      if (clickReducer.card.hovered){
        d3.select(myRef.current).selectAll('circle').filter((e) => e.id == clickReducer.card.id).attr('r', 10)
      } else {
        d3.select(myRef.current).selectAll('circle').attr('r', 5)
      }
    }
  }, [clickReducer.card])

  useEffect(() => {
    if (myRef.current && animatedNodes.length){
      if (clickReducer.category){
        d3.select(myRef.current).selectAll('circle').filter((e) => e.artist == clickReducer.category).attr('r', 10)
      } else {
        d3.select(myRef.current).selectAll('circle').attr('r', 5)
      }
    }
  }, [clickReducer.category])

  //useLayoutEffect(()=>{
  useLayoutEffect(()=>{
    const simulation = d3
    .forceSimulation(nodes)
    .force('charge', d3.forceManyBody().strength(-1.70))
    .force('collide', d3.forceCollide(7).strength(0.5))
    .force("x", d3.forceX(width / 2).strength(0.015))
    .force("y", d3.forceY(height / 2).strength(0.015))
    .force("radial", d3.forceRadial(350, 500,500).strength(0.035))
    .on("tick", ticked)

    if ( nodes.length > 15){
      simulation
        .tick(50)
        .stop()
    } else { 
      simulation
        .tick(20)
        .stop()
    }

    function onMouseOver(d , i) {
      d3.select(this).attr('r', 10)
      dispatch({
        type: "HOVERED",
        payload: {
          ...i,
          r: 10
        }
      });
    }
    function onMouseOut(d, i) {
      d3.select(this).attr('r', 5)
      dispatch({
        type: "HOVERED",
        payload: null
      })
    }
    function onClick() {
      window.open(d3.select(this).attr('full_link'), "_blank")
    }

    const svg = d3.select(myRef.current).select('g')

    function ticked() {
      svg.selectAll('circle')
        .data(simulation.nodes(), node => node.id)
        .join('circle')
        .attr("r", node => node.r)
        .attr("cx", node => node.x)
        .attr("cy", node => node.y)
        .attr("name" , node => node.name)
        .attr("fill", node => colors[node.artist])
        .attr("author", node => node.author)
        .attr("published_in", node => node.published_in)
        .attr("software", node => node.software)
        .attr("artist", node => node.artist)
        .attr("description", node => node.description)
        .attr("full_link", node => node.full_link)
        .attr("id", node => node.id)
        .attr("stroke", "black")
        .attr("pdf", node => node.pdf)
        .attr("png", node => node.png)
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("click", onClick)
    }
    ticked()
  }, [animatedNodes]);

  return (
    <svg viewBox="0 0 1000 1000" className={classes.svg} ref={myRef}>
      <g/>
    </svg>)
}

export const Circle = () => {
  /*
   * Manage Node
   * Manage 
   * Async wait for Plot
  */

  const dataReducer = useSelector((state) => state.dataReducer);
  const [nodes, setNodes] = useState([])

  useEffect(() => {
    setNodes([...dataReducer.nodes])
  }, [dataReducer.nodes])

  return (
    <Grid container>
      <Grid item xl={10} md={12}>
        <ForceGraph nodes={nodes}/>
      </Grid>
      <Grid item xl={12} md={12}>
        <Legend />
      </Grid>
    </Grid>
  );
}
