import Header from "./Header";
import Footer from "./Footer";
import React, { useState } from "react";
import { darkTheme, lightTheme } from "../../themes/Theme";

import { Box } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import AboutPage from "../pages/aboutPage";
import MainPage from "../pages/mainPage";

import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.primary,
    width: "100%",
    height: "100%",
    direction: "flex",
  },
  header: {
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "200px",
    maxHeight: "200px",
  },
  content: {
    paddingTop: "0px",
    minHeight: "700px",
  },
  searchPaper: {
    overflow: "hidden",
  },
  bubblePlot: {
  },
  toolBar: {
  },
  dataInfo:{
    padding: "20px 20px 20px 20px",
  },
  footer: {
    paddingBottom: "0px",
    heihgt: "200px",
    direction: "sticky"
  },
}));

export default function Layout() {
  const [lighton, setLight] = useState(false);
  const classes = useStyles();
  /*
  <Route exact path="/">
    <MainPage lighton={lighton} setLight={setLight} />
  </Route>
  */

  return (
    <ThemeProvider theme={lighton ? darkTheme : lightTheme}>
      <Router>
        <CssBaseline />
        <Box className={classes.root}>
          <Header />
          <Box className={classes.content}>
            <Switch>
              <Route exact path="/index.html" component={() => <MainPage lighton={lighton} setLight={setLight} />} />
              <Route exact path="/" component={() => <MainPage lighton={lighton} setLight={setLight} />} />
              <Route exact path="/about" component={AboutPage} />
            </Switch>
          </Box>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}
