import React from "react";
import CheckboxLabels from "./checkboxLabels";
import BookSelect from "./bookChoice";
import { makeStyles } from "@material-ui/styles";
import ToogleDarkThemeSwitch from "./darkTheme";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "15px 15px 15px 15px",
  },
});

export default function ToolBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ToogleDarkThemeSwitch {...props} />
      {/*<CheckboxLabels />*/}
      {/*<BookSelect />*/}
    </div>
  );
}
