import { grey } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

/*
      primary: 45,
      secondary: 25,
      tertiary: 15,
      quaternary: 7,
*/

const baseTheme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          "scrollbar-width": "0px",
        },
        "*::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
        },
      },
    },
  },
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: "10px",
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
  text: {
    fontSize: {
      primary: "3em",
      secondary: "1.5em",
      tertiary: "0.8em",
      quaternary: "1em",
      fith: "0.8em",
      sixth: "0.7em",
    },
  },
  palette: {
    checkbox: {
      primary: grey[900],
      secondary: grey[500],
    },
  },
});

const darkTheme = createMuiTheme({
  ...baseTheme,
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: grey[100],
      },
      colorSecondary: { 
	color: grey[100],
      }
    },
  },
  palette: {
    type: "dark",
    primary: {
      main: grey[200],
    },
    secondary: {
      main: grey[400],
    },
    text: {
      primary: grey[500], //
      secondary: grey[300], //
      tertiary: grey[300], //
      quaternary: grey[800],
      fithary: grey[800],
    },
    checkbox: {
      primary: grey[200],
      secondary: grey[300],
    },
    background: {
      primary: grey[700], //White
      secondary: grey[700], //Black #A88BB3
      tertiary: grey[800], //
      quaternary: grey[600],
    },
  },
});

const lightTheme = createMuiTheme({
  ...baseTheme,
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: grey[900],
      },
      colorSecondary: { 
        "&$checked" : {
          color: grey[900],
        }
      }
    },
  },
  palette: {
    type: "light",
    primary: {
      main: grey[200],
      // main: "#fafafa",
    },
    secondary: {
      main: grey[200],
    },
    text: {
      primary: grey[900], // Header and Footer
      secondary: grey[600], // Title in Component
      tertiary: grey[700], // left scrolling bar content
      quaternary: grey[900], // About &FAQ
    },
    checkbox: {
      primary: grey[900], // Header and Footer
      secondary: grey[300],
    },
    background: {
      primary: grey[500], //White
      secondary: grey[300], //Black #A88BB3
      tertiary: grey[100], //
      quaternary: grey[300],
    },
  },
});

export { darkTheme, lightTheme };
