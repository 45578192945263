import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import GridList from "@material-ui/core/GridList";
import Grid from "@material-ui/core/Grid";
import GridListTile from '@material-ui/core/GridListTile';


import { useSelector } from "react-redux";
import ContentCard from "./searchCard";
import { makeStyles } from "@material-ui/styles";
import InputExampleLeftLoading from "./searchInput";

export default function SearchPaper() {
  // Styling

  // Fetch Searchable dataset
  const searchReducer = useSelector((state) => {
    return state.searchReducer;
  });

  //useEffect(() => {
  //}, state.dataReducer)


  return (
    <Box >
      <Grid 
        direction="column"
      >
        <GridList
          cellHeight={"auto"}
          cols={1}
          spacing={1}
        >
        <InputExampleLeftLoading/>
          {searchReducer.res.map((character, index) => (
            <GridListTile>
              <ContentCard {...character} key={index}/>
            </GridListTile>
          ))}
        </GridList>
      </Grid>
    </Box>
  );
}
