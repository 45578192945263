const metadata = {
  artists: [],
  teams: [],
  softwares: [],
  colors: {},
}
const initalState = {
  ...metadata,
  data: [],
  nodes: [],
  software: {},
  category: {},
  clusters: {},
}

/*
 * MetaData
 *  artists  : []
 *  softwares : []
 *  teams : []
 *
 * Filters:
 *  software: 
 *  category: 
 *
*/

const languageMap = {
  R: 'r',
  Matlab: 'm',
  Python: 'py',
  Sas: 'sas',
  Solidity: 'sol',
}
const languageMap2 = {
  r: 'R',
  m: 'Matlab',
  py: 'Python',
  sas: 'Sas',
  sol: 'Solidity',
}

const FilterState = (state) => {
  /*
   *
   *
   *
   *
  */

  // console.log("FilterState", state)
  let software = Object.keys(Object.fromEntries(
    Object.entries(state.software).filter(([, value]) => value )))
  var artists =  Object.keys(Object.fromEntries(Object.entries(state.clusters).filter(([, value]) => value )));
  var category =  Object.keys(Object.fromEntries(Object.entries(state.category).filter(([, value]) => value )));

  // console.log("FilterState with the following", software, category, artists)

  const new_data =  state.data.filter( 
    d => d.software.filter(x => software.includes(x)).length &&
    category.includes(d.team) &&
    artists.includes(d.artist));
  return {
    ...state,
    nodes: [...new_data]
  }


  // filter by hovering
  // if (category.length){
  //   //
  //   }
  // }else{
  //   const new_data =  state.data.filter( d => d.software.filter(x => software.includes(x)).length &&
  //     artists.includes(d.artist));
  //   return {
  //     ...state,
  //     nodes: [...new_data]
  //   }
  // }
}

// const UpdateDataSet = (st
const dataReducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATE_DATA": 
      // artist
      // programming languages
      // category
      //  artists: Object.assign(...action.payload.metadata.artists.map(k => ({ [k]: true }))),
      //  teams: Object.assign(...action.payload.metadata.teams.map(k => ({ [k]: false }))),
      //  softwares: Object.assign(...action.payload.metadata.softwares.map(k => ({ [k] : false}))),
      
      // extract meta data
      const metadata = {
        artists: [...action.payload.metadata.artists],
        teams: [...action.payload.metadata.teams],
        softwares: [...action.payload.metadata.softwares],
        colors: {...action.payload.metadata.colors}
      }

      const checkbox = {
        // software: Object.assign(...metadata.softwares.map(k => ({ [k]: true }))),
        software: Object.assign(...metadata.softwares.map(k => ({ [k]: true }))),
        category: Object.assign(...metadata.teams.map(k => ({ [k]: true }))),
        clusters: Object.assign(...metadata.artists.map( k => ({ [k]: true}))),
      }

      return {
        ...state,
        ...metadata,
        ...checkbox,
        data: [...action.payload.data],
        nodes: [...action.payload.data]
      }
    case "FILTER_BY_SOFTWARE":
      state.software = {
        ...state.software,
        [action.payload.name] : action.payload.checked,
      }
      return FilterState(state)

    case "FILTER_BY_CATEGORY":
      state.category = {
        ...state.category,
        [action.payload.name] : action.payload.checked,
      }
      return FilterState(state)

    case "FILTER_BY_ARTIST":
      state.clusters = {
        ...state.clusters,
        [action.payload.name] : action.payload.checked,
      }
      return FilterState(state)

    default:
      return state;
  }
};
export default dataReducer;
