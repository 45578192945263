const initalState = {
  hovered: {
    published_in: '',
    author: '',
    software: [],
    description: '',
    id: -1,
    pdf: null,
    png: null,
  },
  category: "",
  card: {
    published_in: '',
    author: '',
    software: [],
    description: '',
    id: -1,
    pdf: null,
    png: null,
  }
};

/*
 * This manages the currently hovered element to display Tooltip
 *  in SearchBar
 *  in 
 * 
*/

const clickReducer = (state = initalState, action) => {
  switch (action.type) {
    case "HOVERED":
      if (action.payload){

        return {...state, hovered: {...action.payload}}
      }else{
        return {...state,
            hovered: {
              published_in: '',
              author: '',
              software: [],
              description: '',
              id: -1,
              png: null,
            },
        }
      }

    case "HOVERED_CATEGORY":
      return {...state, category: action.payload}

    case "HOVERED_CARD":
      return {...state, hovered: action.payload, card: action.payload}

    default:
      return state;
  }
};
export default clickReducer;
