import React from "react";

import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Tooltip from '@material-ui/core/Tooltip';

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Rlogo from  '../../containers/logos/r.gif'
import Mlogo from  '../../containers/logos/matlab.gif'
import Plogo from  '../../containers/logos/python.gif'
import Slogo from  '../../containers/logos/sas.gif'
import slogo from  '../../containers/logos/solidity.gif'
import cpplogo from  '../../containers/logos/cpp.gif'
import shelllogo from  '../../containers/logos/shell.gif'

/*
  maxWidth: "100%", 
  maxHeight: "500px", 
  maxWidth: "300px",
  maxHeihgt: "300px",
  footheader:
    align: "left",
*/

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.tertiary,
    fontSize: theme.text.fontSize.tertiary,
    padding: "5px 5px 5px 5px",
    maxWidth: "100%",
    margin: "5% 20px 5% 5%"
  },
  [theme.breakpoints.up('md')] :
  {
    root: {
      overflow: "auto",
      backgroundColor: theme.palette.background.secondary,
      color: theme.palette.text.tertiary,
      fontSize: theme.text.fontSize.quaternary,
      padding: "5px 5px 5px 5px",
      maxWidth: "100%",
      margin: "5% 20px 5% 5%"
    },
  },
  pictureBox: {
    padding: "20px 20px 20px 20px",
    width: "100%",
    height: "100%",
    maxWidth: "500px",
    maxHeight: "500px",
  },
  picture: {
    width: "100%",
    height: "100%",
  },
  rootDiv: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  header: {
    fontSize: theme.text.fontSize.primary,
    color: theme.palette.text.secondary,
  },
  footheader: {
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    borderRadius: "20px", 
    padding: "10px 10px 10px 10px",
    align: "right",
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.tertiary,
  },
  name: {
    fontSize: theme.text.fontSize.secondary,
  },
  author: {
    fontFamily: "cursive",
  },
  publication: {
    fontSize: theme.text.fontSize.sixth,
    overflow: "hidden",
  },
  software: {
  },
  softwareInfo: {
  },
  description: {
    paddingTop: "20px",
  },
  pdf: {
    color: "#F56BFF",
  },
  logo: {
    width: "15px",
    height: "15px",
  },
  artist: {
    fontWeight: 600,
    fontSize: theme.text.fontSize.tertiary,
    paddingTop: "20px",
  },
}))

const SoftwareInfo = (props) => {
  const classes = useStyles();
  
  return (
    <>
      { props.includes('sol') ? (
      <Tooltip title="Solidity" aria-label="add">
        <img className={classes.logo} src={slogo}/>
      </Tooltip>) : null}
      { props.includes('py') ? (
      <Tooltip title="Python" aria-label="add">
        <img className={classes.logo} src={Plogo}/>
      </Tooltip>) : null}
      { props.includes('m') ? (
      <Tooltip title="Matlab" aria-label="add">
        <img className={classes.logo} src={Mlogo}/>
      </Tooltip>) : null}
      { props.includes('sas') ? (
      <Tooltip title="Sas" aria-label="add">
        <img className={classes.logo} src={Slogo}/>
      </Tooltip>) : null}
      { props.includes('r') ? (
      <Tooltip title="R" aria-label="add">
        <img className={classes.logo} src={Rlogo}/>
      </Tooltip>) : null}
      { props.includes('cpp') ? (
      <Tooltip title="C++" aria-label="add">
        <img className={classes.logo} src={cpplogo}/>
      </Tooltip>) : null}
      { props.includes('sh') ? (
      <Tooltip title="Shell" aria-label="add">
        <img className={classes.logo} src={shelllogo}/>
      </Tooltip>) : null}
    </>
  )
}

const DataInfo = () => {
  /*
   * 
   * 
  */

  const classes = useStyles();
  const clickReducer = useSelector((state) => {
    return state.clickReducer;
  });
  const dispatch = useDispatch();

  return (
    <Paper
      className={classes.root}
      backgroundcolor="secondary"
      onClick={() => window.open(clickReducer.hovered.full_link, "_blank")}
      onMouseEnter={() => {
        dispatch({
          type: "SET_ACTIVE",
          payload: clickReducer.hovered,
        });
      }}
      onMouseLeave={() => {
        dispatch({
          type: "SET_NOT_ACTIVE",
          payload: clickReducer.hovered,
        });
      }}
    >
      <CardContent
      >
        <Box className={classes.footheader}>
          <Grid
            container
            spacing={1}
            alignItems="baseline"
            justifyContent="space-between"
          >
            { clickReducer.hovered.author ?
              (
              <>
                <Grid item md={10} xl={9}> 
                  <Typography noWrap variant="h2" className={classes.name}>
                    {clickReducer.hovered.name}
                  </Typography>
                </Grid>
                <Grid item md={2} xl={3} >
                  {SoftwareInfo(clickReducer.hovered.software)}
                </Grid>
                <Grid item md={10} xl={11}>
                  <Typography className={classes.publication}>
                    Authored by {clickReducer.hovered.author}
                  </Typography>
                </Grid>
                {
                  clickReducer.hovered.team == "none" ? (
                    null
                  ) : (
                  <Grid item md={2} xl={1}>
                    <Typography className={classes.publication}>
                      {clickReducer.hovered.team}
                    </Typography>
                  </Grid>
                  )
                }
              </>
              ) 
                : 
              (
                <>
                <Grid item md={10} xl={10}> 
                  <Typography noWrap variant="h2" className={classes.name}>
                    {clickReducer.hovered.name}
                  </Typography>
                </Grid>
                  <Grid item md={1} xl={2} >
                    {SoftwareInfo(clickReducer.hovered.software)}
                </Grid>
                </>
              )}
          </Grid>
        </Box>
        <Box className={classes.description}>
          {clickReducer.hovered.description}
          <br/>

          {clickReducer.hovered.png ? (
            <Box className={classes.pictureBox}>
              <img src={clickReducer.hovered.png.download_url} className={classes.picture}/>
            </Box>) : null }
        </Box>
        <Box className={classes.artist}>
          {clickReducer.hovered.artist}
        </Box>
      </CardContent>
    </Paper>
  );
};

/*
{clickReducer.hovered.pdf ? (
  <div>
    <a href={clickReducer.hovered.pdf.html_url} className={classes.pdf}>
      PDF
    </a>
  </div>
) : null}
*/
  
export default DataInfo
