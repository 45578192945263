import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { Input } from "semantic-ui-react";
//import "semantic-ui-css/semantic.min.css";
import { makeStyles } from "@material-ui/styles";
import Fuse from "fuse.js";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";

/*
 * Manage Input and Fuse
 * 
 * 
 * 
*/

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.text.quaternary,
    paddingBottom: "0px",
    paddingLeft: "5px",
  },
  input: {
    maxWidth: "100%",
    fontColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  switch: {},
}));

const res_nmbr = 30;

export default function InputExampleLeftLoading() {

  const fuseOptions = {
    useExtendedSearch: true,
    includeScore: true,
    includeMatches: true,
    findAllMatches: true,
    ignoreLocation: false,
    keys: ["name", "artist", "id", "description", "author"],
    threshold: 0.3,
  };

  // states
  const classes = useStyles();
  const [isloading, setLoading] = useState(false);
  const [query, updateQuery] = useState("");
  const [fuse, setFuse] = useState(new Fuse([], fuseOptions));

  // redux
  const dataReducer = useSelector(state => state.dataReducer);
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    setLoading(true);
    // Update dataset to search in Fuse
    setFuse(new Fuse( dataReducer.nodes, fuseOptions));
    // propagate
    // Refresh search results
    dispatch({
      type: "SEARCH_RESULT",
      payload: fuse.search(query, {limit: res_nmbr}).map(x => x.item)
    })
    setLoading(false);
  }, [dataReducer])

  function onSearch({ currentTarget }) {
    setLoading(true);

    //
    updateQuery(currentTarget.value);

    // propagate
    dispatch({
      type: "SEARCH_RESULT",
      payload: fuse.search(currentTarget.value, {limit: res_nmbr}).map(x => x.item)
    })
    setLoading(false);
  }

  return (
    <Box className={classes.root}>
      {isloading ? (
        <TextField
          loading
          icon={{ name: "search", circular: true, link: true }}
          iconPosition="right"
          placeholder="Search..."
          className={classes.input}
          onChange={onSearch}
          value={query}
        />
      ) : (
        <TextField
          icon={{ name: "search", circular: true, link: true }}
          iconPosition="right"
          placeholder="Search Quantlets..."
          className={classes.input}
          onChange={onSearch}
          value={query}
        />
      )}
      
    </Box>
  );
}
