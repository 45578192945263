import React from 'react'
import ReactDOM from "react-dom";


import "./index.css";

import Layout from "./components/layout/Layout";
//import { Container } from "@material-ui/core";
import { Provider } from "react-redux";
import rootReducer from "./reducers/rootReducer";

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

// console.log("ENV", process.env);
// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

ReactDOM.render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  document.getElementById("root")
);
