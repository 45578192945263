const initalState = {
  res : [], 
};
// TODO: change index to id
const searchReducer = (state = initalState, action) => {
  switch (action.type) {
    case "SEARCH_RESULT":
      // console.log("SEARCHRESULT", action.payload);
      return {
        ...state,
        res: action.payload
      };
    default:
      return state;
  }
};

export default searchReducer;
