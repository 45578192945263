import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import { useDispatch } from "react-redux";


//
//    margin: "0px 0px 5px 0px",
//    padding: "0px 5px 5px 00px",
//    minWidth: "95%",
//    maxWidth: "95%",
//    maxHeight: "15%",
//    padding: "0px 10px 10px 0px"
//    margin: "0px 0px 0px 5px",
//    padding: "0px 0px 0px 0px",


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "0px 5px 5px 5px"
  },
  name: {
    margin: "5px 5px 5px 5px",
    fontStyle: "italic",
    fontSize: theme.text.fontSize.tertiary
  }, 
}));

const ContentCard = (props) => {

  const classes = useStyles();
  const [hovered, setHovered] = useState(0);
  const dispatch = useDispatch();

  return (
      <Card
        className={classes.paper}
        variation="elevation"
        elevation={hovered}
        backgroundcolor="secondary"
        onMouseEnter={() => {
          setHovered(6);
          dispatch({
            type: "HOVERED_CARD",
            payload: {
              ...props,
              hovered:true,
            }
          });
        }}
        onMouseLeave={() => {
          setHovered(0)
          dispatch({
            type: "HOVERED_CARD",
            payload: {
              published_in: '',
              author: '',
              software: [],
              description: '',
              id: props.id,
              hovered: false,
            },
          });
        }}
        onClick={() => window.open(props.full_link)}
      >
        <Typography noWrap className={classes.name}>
          {props.name}
        </Typography>
      </Card>
  );
};

export default ContentCard;
