import React, { useState, useEffect } from "react";

import Grid from '@material-ui/core/Grid';

// SearchBar
import SearchPaper from "../searchbar/searchPaper";

// Plot
import { Circle } from "../d3Plot/plot"

// Filter
import ToolBar from "../toolbar/toolBar";

// Tile
import DataInfo from "../pages/dataInfo";

//
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// import data from "../../data.json";


export default function MainPage({ lighton, setLight }) {

  const clickReducer = useSelector((state) => {
    return state.clickReducer;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    /*
     * TO USE AS LOCALHOST
      dispatch({
        type: "UPDATE_DATA",
        payload: data,
    })
    */
    fetch("https://cloud.virtomat.io:8080/swift/v1/AUTH_d5564e07932247f78866cc606a127fe0/quantlet/myNewD3Dataset.json")
      .then((response) => {
        console.log("RESPONSE", response)
        return response.json()
      })
      .then((data) => {
        console.log("DATA", data);
        dispatch({
          type: "UPDATE_DATA",
          payload: data,
        })
      })
  }, [])


  useEffect(() => {
    if (clickReducer.hovered.id > -1) {
      setInfo(true)
    } else {
      setInfo(false)
    }
  }, [clickReducer])

  const [info, setInfo] = useState(false)

  return (
    <Grid container
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between">
      <Grid item xl={1} md={2}>
        <SearchPaper />
      </Grid>
      <Grid item xl={5} md={5}>
        <Circle />
      </Grid>
      <Grid item xl={5} md={4}>
        {info ? <DataInfo /> : null}
      </Grid>
      <Grid item xl={1} md={1}>
        <ToolBar lighton={lighton} setLight={setLight} />
      </Grid>
    </Grid>
  )
}

