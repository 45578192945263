import React, { useState, useEffect } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { category } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px 00px 00px",
    "& .MuiFormLabel-root": {
      color: theme.palette.checkbox.primary,
    }
  },
  title: {
    color: theme.palette.checkbox.primary,
    fontSize: theme.text.fontSize.tertiary,
    '&$focus': { 
        color: theme.palette.checkbox.primary
    },
    '&:hover': { 
        color: theme.palette.checkbox.primary
    },
  },
  checkbox: {
    labelColor: theme.palette.checkbox.secondary,
    fontSize: theme.text.fontSize.quaternary,
  },
}));

export default function CheckboxLabels() {
  const classes = useStyles();

  const [state, setState] = useState(category);
  const dispatch = useDispatch();

  const selector = useSelector(state => state.dataReducer);
  useEffect(() => {
    setState(selector.category)
  }, [selector])

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    dispatch({
      type: "FILTER_BY_CATEGORY",
      payload: { name: [event.target.name], checked: event.target.checked },
    });
  };

  return (
    <Box className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel className={classes.title}>
          Categories
        </FormLabel>
        <FormGroup column>
          {Object.entries(state).map(([key, value]) => {
            // key == "" is true if <empty-string> in jsx
            return ((key != "none") ? (
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={value}
                    onChange={handleChange}
                    name={key}
                  />
                }
                label={key}
                key={key}
              />
            ):(
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={value}
                    onChange={handleChange}
                    name={key}
                  />
                }
                label={"None"}
                key={key}
              />
            ))
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
}
