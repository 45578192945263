import { combineReducers } from "redux";

import dataReducer from "./dataReducer";
import clickReducer from "./clickReducer";
import searchReducer from "./searchReducer";

const rootReducer = combineReducers({
  dataReducer,
  clickReducer,
  searchReducer,
});

export default rootReducer;
