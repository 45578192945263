import React from "react";
import Box from "@material-ui/core/Box";
//import Container from "@material-ui/core/Container";
//import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import quantnet_logo from "../../containers/logos/image2vector3.svg";
import { Icon, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.background.quaternary,
  },
  link: {
    backgroundColor: theme.palette.text.quaternary,
    color: 'inherit',
    textDecoration: 'inherit'
  },
  imageIcon: {
    height: 60,
    verticalAlign: "top",
  },
  iconRoot: {
    fontWeight: "bold",
    fontSize: theme.text.fontSize.primary,
    color: theme.palette.text.quaternary,
  },
  aboutRoute: {
    fontWeight: "bold",
    fontSize: theme.text.fontSize.secondary,
    color: theme.palette.text.quaternary,
    textAlign: "left",
    padding: "0 20px 10px 20px",
  },
  qandaRoute: {
    fontWeight: "bold",
    fontSize: theme.text.fontSize.secondary,
    color: theme.palette.text.quaternary,
    textAlign: "right",
    padding: "0 20px 10px 20px",
  },
}));

export default function Header() {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <Grid item xl={1}>
          <Link to="/about" className={classes.link}>
            <Typography className={classes.aboutRoute}>About</Typography>
          </Link>
        </Grid>
        <Grid item xl={3}>
          <Link to="/" className={classes.link}>
            <Typography>
              <Icon className={classes.iconRoot}>
                <img className={classes.imageIcon} src={quantnet_logo} />
                uantlet
              </Icon>
            </Typography>
          </Link>
        </Grid>
        <Grid item xl={1}>
          <Typography
            className={classes.qandaRoute}
            onClick={() =>
              window.open(
                "https://github.com/QuantLet/Styleguide-and-FAQ/blob/master/guidelines/20210706_Styleguide_Guide_GitHub_FAQ.pdf",
                "_blank"
              )
            }
          >
            FAQ
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
