import "./aboutPage.css";
import Container from "@material-ui/core/Container";
import Tooltip from '@material-ui/core/Tooltip';
import Box from "@material-ui/core/Box"
//import Avatar from "@material-ui/core/Avatar";

//import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import React, { useRef } from "react";
import { unmountComponentAtNode } from "react-dom";
import { makeStyles } from "@material-ui/styles";

import Rlogo from '../../containers/logos/r.gif'
import Mlogo from '../../containers/logos/matlab.gif'
import Plogo from '../../containers/logos/python.gif'
import Slogo from '../../containers/logos/sas.gif'
import slogo from '../../containers/logos/solidity.gif'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary
  },
  header: {
    color: theme.palette.text.secondary,
    background: theme.palette.text.primary,
    padding: "5px 5px 5px 5px"
  },
}));

export default function AboutPage() {
  /*
   * 
   *
   * 
   */

  const projectRef = useRef(null);
  const githubRef = useRef(null);
  const teamRef = useRef(null);
  const impressumRef = useRef(null);
  const classes = useStyles();

  const executeScroll = (ref) => () => ref.current.scrollIntoView();


  return (
    <Box>
      <Container>
        <div className={classes.root}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/" component={RouterLink}>Main Page</Link>
            <Link color="inherit" onClick={executeScroll(projectRef)}>Project</Link>
            <Link color="inherit" onClick={executeScroll(githubRef)}>GitHub</Link>
            <Link color="inherit" onClick={executeScroll(teamRef)}>Team</Link>
            <Link color="inherit" onClick={executeScroll(impressumRef)}>Impressum</Link>
          </Breadcrumbs>
        </div>
      </Container>
      <Container ref={projectRef}>
        {/* Intro Section */}
        <h2 className={classes.header}>Project</h2>
        <div className="styled-table">
          <p>
            Quantlet is designed as a web-interface to freely exchange numerical
            methods, called Quantlets. Quantlet aims to introduce a centralized
            system that is constituted by documents from different scientific
            areas, submitted by various authors from professional researchers to
            university students.
          </p>
          <p>
            As part of the Collaborative Research Center, the Center for Applied
            Statistics and Economics and the International Research Training
            Group (IRTG) 1792, Quantlet contributes to the goal of strengthening
            and improving empirical economic research in Germany.
          </p>

          <p>At present Quantlet contains source code written mainly in:</p>
          <p id="software">
            <Tooltip title="Solidity" aria-label="add">
              <img className="photo" src={slogo}/>
            </Tooltip>
            <Tooltip title="Python" aria-label="add">
              <img className="photo" src={Plogo}/>
            </Tooltip>
            <Tooltip title="Matlab" aria-label="add">
              <img className="photo" src={Mlogo}/>
            </Tooltip>
            <Tooltip title="Sas" aria-label="add">
              <img className="photo" src={Slogo}/>
            </Tooltip>
            <Tooltip title="R" aria-label="add">
              <img className="photo" src={Rlogo}/>
            </Tooltip>
          </p>
        </div>
      </Container>
      <Container ref={githubRef}>
        <h2 className={classes.header}>
          <Link 
            color="inherit" 
            onClick={() => {
              window.open("https://github.com/QuantLet", "_blank")
            }}>
            GitHub
          </Link>
        </h2>
        <div className="styled-table">
          <p>
            {"Quantlet is a GitHub organization with diverse repositories of scientific information consisting of statistics-related documents and program codes."}
          </p>
          <p>
            {"The advantages of Quantlet are:"}
          </p>
          <ul>
            <li>
              Open access to all research and teaching related codes and
              programs,
            </li>
            <li>
              Reproducibility of research results increases its accountability
              and reliability,
            </li>
            <li>
              {"Full integration with GitHub, implemented with "}
              <b>PyGithub</b>
              {" package available on"}
              <Link 
                color="inherit" 
                onClick={() => {
                  window.open("https://pypi.org/project/PyGithub/", "_blank")
                }}>
                <b>{" PyPI"}</b>
              </Link>
            </li>
            <li>
              {'Text Mining Pipeline providing Information Retrieval, document clustering and visualizations realized with a '}
              <em>{'"GitHub API based Quantlet Mining infrastructure in R"'}</em>
            </li>
            <li>
              Ease of discovery and use of your technology and research results,
              everything in a single GitHub Markdown page
            </li>
            <li>
              {"Standardized audit of your codes based on the "}
              <Link 
                color="inherit" 
                onClick={() => {
                  window.open("https://github.com/QuantLet/Styleguide-and-FAQ", "_blank")}}
              >
                <b>{"StyleGuide"}</b>
              </Link>
            </li>
          </ul>
          
          <Link 
            color="inherit" 
            onClick={() => {
              window.open("https://github.com/QuantLet/Styleguide-and-FAQ","_blank")}}>
              {" The Quantlet Styleguide "}
          </Link>
          {"enables a standardized audit and validation of new Quantlets. The Styleguide contains several subsections:"}
          <ul>
            <li>
              {"Styleguide of Quantlets: an overview of the structure of a Quantlet"}
            </li>
            <li>
              {"Characteristics and mandatory data fields of the YAML Metainfo.txt file "}
              <em>Metainfo.txt</em>
            </li>
            <li>Examples of complete and correct Metainfo.txt</li>
            <li>The main YAML rules most relevant for Quantlet</li>
            <li>
              Basic instructions for the 
              <Link 
                color="inherit" 
                onClick={() => {
                window.open("https://desktop.github.com/", "_blank")}}
              >
                {' GitHub Desktop client'}
              </Link>
            </li>
          </ul>
        </div>
      </Container>
      <Container ref={teamRef}>
        <h2 className={classes.header}>Team</h2>
        <div className="styled-table">
          <p>
            {"Responsible/Webmaster:"}
          </p>
          <ul>
            <li style={{ marginBottom: "5px" }}>
              <Link 
                color="inherit" 
                onClick={() => {
                  window.open("https://www.wiwi.hu-berlin.de/de/forschung/irtg/lvb/members/personalpages/wh", 
                  "_blank")}}>
                <b>Prof. Dr. Wolfgang Härdle</b>
              </Link>
            </li>
          </ul>
          <br />
          <p>
            Support:
          </p>
          <ul>
            <li style={{ marginBottom: "5px" }}>
              <Link 
                color="inherit" 
                onClick={() => {
                  window.open("https://www.wiwi.hu-berlin.de/de/forschung/irtg/participating-faculty/students/personal-pages/julian-winkel/Julian%20Winkel", "_blank")}}>
                <b>Julian Winkel</b>
              </Link>
            </li>
            <li>
              <Link 
                color="inherit" 
                onClick={() => {
                  window.open("https://www.wiwi.hu-berlin.de/de/forschung/irtg/participating-faculty/students/personal-pages/elizaveta-zinovyeva",
                  "_blank")}
                }>
              
                <b>Elizaveta Zinovyeva</b>
              </Link>
            </li>
          </ul>
          <br />
          <p>
            {"List of members of the Quantlet GitHub Organization:"}
          </p>
          <ul>
            <li style={{ marginBottom: "5px" }}>
            <Link 
              color="inherit" 
              onClick={() => {
                window.open("https://github.com/orgs/QuantLet/people",
                "_blank")}
              }>
              <b>{"Quantlet's team "}</b>
            </Link>
            </li>
          </ul>
          <br />
        </div>
      </Container>
      <Container ref={impressumRef}>
        <h2 className={classes.header}>Impressum</h2>

        <div className="styled-table">
          <div className="panel-body">
            Unter den Linden 6
            <br />
            10099 Berlin
            <br />
            Germany
            <br />
             {"E-Mail: info@quantinar.com"}
            <br />
          </div>
        </div>
      </Container>
    </Box>
  );
}
