import React, { useState, useEffect } from "react";

//
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Grid, Paper, Box } from "@material-ui/core";

import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  block: {
    color: "black",
    borderWidth: "0",
    padding: "5px 0px 5px 0px",

  },
  legendContent: {
    padding: "3px 3px 3px 3px",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    borderRadius: "20px",
    alignItems: 'center',
    height: "100%",
  },
  paper: {
    backgroundColor: 'inherit',
    border: "0px",
    fontSize: theme.text.fontSize.quaternary,
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]:
  {
    paper: {
      backgroundColor: 'inherit',
      border: "0px",
      fontSize: theme.text.fontSize.fith,
      textAlign: 'center',
      color: theme.palette.text.quaternary
    }
  }
}))

export const Legend = () => {
  /*
   * Legend
   * D3Plot 
  */

  // When a legend item is hovered dispatch update state so plot can adjust
  const dispatch = useDispatch();
  const selector = useSelector(state => state.dataReducer);
  const classes = useStyles();

  const [artists, setState] = useState({})
  const [colors, setColors] = useState({})

  useEffect(() => {
    setState(selector.clusters)
  }, [selector.clusters])

  useEffect(() => {
    // Zip Colors
    setColors(selector.colors)
  }, [artists])

  return (
    <div className={classes.block} >
      <Grid container spacing={1} justifyContent="flex-start" alignItems="stretch">
       {Object.entries(artists).map(([key, value]) => {
         // console.log("State", artists, " key ", key, " value ", value)
         return value ? (
         <Grid item key={key} md={4} xl={2}>
           <Tooltip key={key} title={key}>
             <Box
               onMouseEnter={() => dispatch({ type: "HOVERED_CATEGORY", payload: key })}
               onMouseLeave={() => dispatch({ type: "HOVERED_CATEGORY", payload: null })}
               key={key} className={classes.legendContent} 
               bgcolor={colors[key]} //TODO
               onClick={() => {
                 dispatch({
                   type: "FILTER_BY_ARTIST",
                   payload: { name: key, checked: false },
                 })
                 setState({
                 ...artists,
                 key: false })
               }}>
               <Paper
                 className={classes.paper}
                 key={key}
                 variant="outlined"
                 >
                 {key}
               </Paper>
             </Box>
           </Tooltip>
         </Grid> ) : 
         (<Grid item key={key} md={4} xl={2}>
           <Tooltip key={key} title={key}>
             <Box
               onMouseEnter={() => dispatch({ type: "HOVERED_CATEGORY", payload: key })}
               onMouseLeave={() => dispatch({ type: "HOVERED_CATEGORY", payload: null })}
               key={key} className={classes.legendContent} 
               bgcolor={grey[500]}
               onClick={() =>{
                 dispatch({
                   type: "FILTER_BY_ARTIST",
                   payload: { name: key, checked: true },
                 })
                 setState({
                   ...artists,
                   key: true 
                 })}
               }>
               <Paper
                 className={classes.paper}
                 key={key}
                 variant="outlined"
                 >
                   <strike>{key}</strike>
               </Paper>
             </Box>
           </Tooltip>
           </Grid>)})}
      </Grid>
    </div>
  );
}
